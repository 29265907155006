.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
  overflow-y: scroll;
  text-align: center;
}

.sidebar hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(107, 114, 128, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Remove scrollbar space */
/* Optional: just make scrollbar invisible */
/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
} */

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem:hover {
  background-color: #cccccc44;
}

.sidebarListItem.active {
  background-color: #0097a722;
}

.sidebarListItem.status {
  color: red;
  font-weight: bold;
}

.sidebarListItem.disabled {
  color: #bbb;
}

.sidebarIcon {
  font-size: 0.9rem;
  padding-right: 16px;
  color: #4b5563;
}

.sidebarIcon.disabled {
  font-size: 0.9rem;
  padding-right: 16px;
  color: #bbb;
}

.viewOptions {
  display: flex;
}

.viewButton {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e1e1e1;
  background: white;
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.viewButton p {
  padding-right: 0.3rem;
}

.viewButton:hover {
  outline: none;
  background: #eee;
}

.viewButton:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.viewButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.viewButtonActive {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e1e1e1;
  background: #6b7280;
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: white;
}

.copyright {
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.userButton {
  background: #eee;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.userButton::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../../assets/person.svg") no-repeat center center;
}

.userButton:focus,
.userButton:hover,
.userButtonActive {
  outline: none;
  background: #eee;
  border: 5px darkgray;
}

 /* MODAL */

 .modal {
  display: block;
  position: fixed;
  left: 35%;
  top: 8%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 35rem;
  max-width: 35rem;
  max-height: auto;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modal h3 {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.modal p {
  padding: 2rem;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.modalBody {
  padding: 1.5rem;
}

.modalBody button {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.materialIcon {
  color: #9ca3af;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 0.5rem;
  border-top-width: 1px;
  justify-content: space-between;
}