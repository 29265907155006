.content {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.inLine {
  display: flex;
  justify-content: space-between;
}

.recordCard {
  cursor: default;
  background-color: white;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 10rem;
  padding-top: 1rem;
}

.recordHeader {
  background-color: #f3f4f6;
  margin-top: 1rem;
  box-shadow: 0px 4px 5px 0.5px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
}

.recordHeader p {
  cursor: pointer;
  padding: 1rem;
}

.recordContent {
  cursor: default;
  background-color: white;
  margin-top: 2rem;
  padding: 1rem;
  transition: 0.3s;
  border-radius: 5px;
}

.recordContent div {
  margin-bottom: 0.5rem;
}

 /* MODAL */

 .modal {
  display: block;
  position: fixed;
  left: 35%;
  top: 5%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 35rem;
  max-width: 35rem;
  max-height: auto;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 999;
}

.modal h3 {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.modal h4 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.modal p {
  padding: 0.3rem;
}

.modalBody {
  padding: 1.5rem;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top-width: 1px;
  /* justify-content: flex-end ; */
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.materialIcon {
  color: #9ca3af;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.textarea {
  border: none;
  width: 100%;
  box-sizing: border-box;
  border-style: solid;
  border-color: #eee;
  background-color: #fff;
  max-height: 15rem;
  resize: none
}