.care p {
  margin-top: 1rem;
}

.week {
  display: flex;
  margin-top: 1rem;
}

.week button {
  margin-right: 1rem;
}

.confirmation {
    display: block;
    position: relative;
    padding-left: 25px;
    padding-top: 0.2rem;
    cursor: pointer;
    font-size: 12px;
    color: #4b5563;
    text-transform: capitalize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: auto;
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.confirmation input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.confirmation img {
  width: 100%;
  height: auto;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* On mouse-over, add a grey background color */
.confirmation:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.confirmation input:checked ~ .checkmark {
  background-color: #009688ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.confirmation input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.confirmation .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}