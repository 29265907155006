.appointBar {
  display: flex;
}

.appointBar button {
  margin-left: 1rem;
}

.container {
  display: block;
  background-color: white;
  border-style: solid;
  border-color: #eee;
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 30rem;
  height: 15rem;
}

.container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container p {
  padding: 0.5rem;
}

.container button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
}

.green {
  color: green;
}

.red {
  color: red;
}

.confirmation {
  display: block;
  position: relative;
  padding-left: 25px;
  padding-top: 0.2rem;
  cursor: pointer;
  font-size: 12px;
  color: #4b5563;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.confirmation input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* On mouse-over, add a grey background color */
.confirmation:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.confirmation input:checked ~ .checkmark {
  background-color: #009688ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.confirmation input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.confirmation .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.appointmentModal {
  display: block;
  position: fixed;
  left: 40%;
  top: 10%;
  max-width: 40%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 25rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.appointmentModal p {
  padding: 0.5rem;
}

.appointmentModal div {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}