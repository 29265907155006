.day {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 20fr;
  grid-template-rows: 40px;
  grid-auto-rows: minmax(50px, auto);
  overflow: auto;
}

.title {
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 1;
}

.cell {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  font-size: 8px;
  text-transform: uppercase;
  color: #99a1a7;
  border: 1px solid #e1e1e1;
  line-height: 50px;
  font-weight: 500;
}

.timezone {
  font-size: 8px;
  text-transform: uppercase;
  color: #888F94;
  margin-top: auto;
  padding-bottom: 0.5rem;
  font-weight: 450;
}

.hour {
  font-size: 8px;
  text-transform: uppercase;
  color: #888F94;
  border-bottom: 1px solid #e1e1e1;;
  line-height: 50px;
  font-weight: 450;
}

.day_title {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #99a1a7;
  padding-left: 1rem;
}

.day_number {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  padding-left: 1rem;
}

.event {
  position: relative;
  z-index: 2;
  padding-left: 0.3rem;
  height: 3rem;
  background-color: #ffcdd2ff;
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.25rem;
  cursor: pointer;
}

.event:hover {
  filter: brightness(90%);
}

.eventConfirmed {
  position: relative;
  z-index: 2;
  padding-left: 0.3rem;
  height: 3rem;
  background-color: #cce2cb;
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.25rem;
  cursor: pointer;
}

.eventConfirmed:hover {
  filter: brightness(90%);
}

.arrowRight {
  height: 0;
  width: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid green;
}

.eventOutMonth:hover {
  filter: brightness(90%);
}

.eventsModal {
  display: flex;
  position: absolute;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 10rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.eventList {
  position: relative;
  z-index: 2;
  padding-left: 0.3rem;
  width: 10rem;
  height: 1.5rem;
  margin-top: 0.6rem;
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.25rem;
  cursor: pointer;
}

.eventList:hover {
  filter: brightness(90%);
}

.eventModalDay {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.eventModalDayNumber {
  text-align: center;
  color: black;
  font-size: 1.5rem;
}

.eventMenuContainer {
  position: relative;
}

/* EVENT MODAL */

.modal {
  display: block;
  position: fixed;
  left: 35%;
  top: 10%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 35rem;
  max-width: 35rem;
  max-height: auto;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modal h3 {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.modal p {
  padding: 2rem;
}

.modalBody {
  padding: 1.5rem;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 0.5rem;
  border-top-width: 1px;
  justify-content: space-between;
}

.status {
  display: flex;
  justify-content: space-between;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.choose {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  min-width: 10rem;
  min-height: 10rem;
  max-height: 10rem;
  border: 1px;
  border-style: solid;
}

.schedule p {
  color: #4b5563;
  font-size: 14px;
  padding: 1rem;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.card {
  display: flex;
  width: 80%;
  height: 10px;
  flex-direction: column;
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  margin: 0.3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 5rem;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.cardActive {
  display: flex;
  width: 80%;
  height: 10px;
  flex-direction: column;
  background-color: #6b7280;
  border-style: solid;
  border-color: #6b7280;
  margin: 0.3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 5rem;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: white;
}

.cardActive:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.cardUser {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  margin: 0.3rem;
  padding: 0.8rem 0.55rem;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}