.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  overflow: auto;
}

.category {
  display: flex;
}

.card {
  background-color: white;
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 12px;
  padding: 0.5rem;
  margin: 3rem;
  width: 50rem;
  min-height: 28rem;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card table {
  border-collapse: collapse;
  width: 100%;
}

.card th, td {
  text-align: left;
  padding: 8px;
}

.card th {
  padding: 1rem;
}

.cardItem {
  cursor: pointer;
  background-color: #fff;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItem:hover {
  filter: brightness(90%);
}

.cardItemOut {
  cursor: pointer;
  background-color: #ffcdd2ff;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemOut:hover {
  filter: brightness(90%);
}

.cardItemConfirmed {
  cursor: pointer;
  background-color: #cce2cb;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemConfirmed:hover {
  filter: brightness(90%);
}

.cardItemOk {
  cursor: pointer;
  background-color: #BBD6FA;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemOk:hover {
  filter: brightness(90%);
}

 /* MODAL */

 .modal {
  display: block;
  position: fixed;
  left: 35%;
  top: 10%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 35rem;
  max-width: 35rem;
  max-height: auto;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modal h3 {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.modal p {
  padding: 2rem;
}

.modalBody {
  padding: 1rem;
}

.modalButton {
  display: flex;
  padding: 0.75rem;
  margin-top: 0.5rem;
  border-top-width: 1px;
  justify-content: center;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 0.5rem;
  border-top-width: 1px;
  justify-content: space-between;
}

.status {
  display: flex;
  justify-content: space-between;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.schedule p {
  color: #4b5563;
  font-size: 14px;
  padding: 0.7rem;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}