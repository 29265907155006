.card {
  cursor: default;
  border-style: none;
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 3rem;
  padding: 1rem;
  width: 42rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.card h4 {
  padding: 0;
}

.card button {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.terAct {
  border-style: none;
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 3rem;
  width: 44rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.terAct h4 {
  padding: 0;
}

.terActHead {
  display: flex;
  border-style: none;
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.terActHead i {
  margin-left: auto;
}

.terActHead h4 {
  padding-top: 0.5rem;
}

.mindGrid {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 0px 0px 12px 12px;
    padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 12rem;
  /* background-color: red; */
}

.terAct:hover {
  background-color: #ccc;
}

.scheduleGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.scheduleGrid p {
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.scheduleGrid p:hover {
  font-weight: bold;
}

.container {
  /* padding-top: 3rem;
  padding-right: 20rem; */
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
  /* align-items: center;
  text-align: center; */
}

.container h2 {
  cursor: pointer;
}

.container h2:hover {
  text-decoration: underline;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.materialIcon {
  color: #9ca3af;
}

.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: block;
  position: fixed;
  left: 25%;
  top: 3%;
  transform: translate(-50%, -50%);
  align-items: center;
  /* ACERTAR REZISE */
  min-width: 38rem;
  max-width: 38rem;
  /* ACERTAR REZISE */
  /* min-height: 15rem;
  max-height: 15rem; */
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modalFooter {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  padding: 0.75rem;
  border-top-width: 1px;
  width: 100%;
}

.modalFooter button {
  margin-top: auto;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.modalBody {
  cursor: default;
  padding: 2rem;
  text-align: justify;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  min-height: 23rem;
  max-height: 23rem;
  overflow-y: scroll;
}

.modalBody p {
  padding-bottom: 0.5rem;
}

.modalBody button {
  margin-bottom: 1rem;
}

.modalBody h3 {
  padding-bottom: 1rem;
  text-align: center;
}

.modalBody2 {
  padding: 2rem;
  text-align: justify;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  min-height: 23rem;
  max-height: 23rem;
  overflow-y: scroll;
}

.modalBody2 p:hover {
  font-weight: bold;
  cursor: pointer;
}