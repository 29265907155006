.activeMind {
  color: #00bcd4ff;
  font-weight: 600;
}

.activeMove {
  color: #ff5252ff;
  font-weight: 600;
}

.activeNutri {
  color: #009688ff;
  font-weight: 600;
}

.card {
  cursor: pointer;
  border-style: none;
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 3rem;
  width: 42rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffb46333;
}

.card h4 {
  padding: 1rem;
}

.card table {
  border-collapse: collapse;
  width: 100%;
}

.card th, td {
  text-align: left;
  padding: 8px;
}

.card th {
  padding: 1rem;
}

.card tr:nth-child(even) {
  background-color: #f2f2f2;
}

.cardContent {
  width: 100%;
  min-height: 12rem;
}

.cardDisabled {
  cursor: pointer;
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 1rem;
  margin-bottom: 3rem;
  width: 42rem;
  min-height: 12rem;
  overflow-y: scroll;
  background-color: #eee;
}

.cardIcon {
  cursor: pointer;
  float: right;
  padding: 0.5rem;
}

.cardIcon:hover {
  background-color: #eee;
  border-radius: 60%;
}

.cardItem {
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

/* .cardItem:hover {
  background-color: #eee;
  border-radius: 10px;
} */

.cardItemActive {
  cursor: pointer;
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #eee;
  border-radius: 10px;
  color: #00bcd4ff;
}

.container {
  padding-top: 3rem;
  padding-right: 20rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
  /* align-items: center;
  text-align: center; */
}

.container p {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.container h2 {
  cursor: pointer;
}

.container h2:hover {
  text-decoration: underline;
}

.content {
  text-align: justify;
}

.content p {
  margin-right: 7rem;
}

.cvBtnIn {
  width: 20rem;
  height: 2.5rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  color:#ffb463ff;
  cursor: pointer;
  font-weight: bold;
}

.cvBtnIn:hover {
  background-color: #eee;
  transition: all ease 0.5s;
  color:#ffb463ff;
}

.title {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.title h4 {
  padding-left: 0.5rem;
}

.title p {
  cursor: pointer;
  padding-right: 1rem;
  font-weight: 600;
}

.myActivities {
  display: flex;
}

.feature {
  display: flex;
  cursor: pointer;
  border-style: double;
  /* Add shadows to create the "card" effect */
  /* box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.5); */
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 1rem;
  margin-right: 5rem;
  min-width: 10rem;
  min-height: 4rem;
  overflow-y: scroll;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.featureMind:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  color: #00bcd4ff;
}

.featureMove:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  /* background-color: #ffcdd2ff; */
  color: #ff5252ff;
}

.featureNutri:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  color: #009688ff;
}

.feature p {
  padding-left: 0.5rem;
  font-weight: 600;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-top: 1px solid #eee;
  width: 47rem;
}

.featureCard {
  display: flex;
  cursor: pointer;
}

.featureImg {
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
}

.green {
  color: green;
}

.red {
  color: red;
}

.error {
  color: red;
  margin-top: 0.5rem;
}

.appointmentModal {
  display: block;
  position: fixed;
  left: 30%;
  top: 5%;
  max-width: 60%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 25rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.appointmentModal p {
  padding: 0.5rem;
}

.appointmentModal div {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

.footer {
  padding-top: 1rem;
  padding-bottom: 5rem;
}

.image {
  width: 6rem;
  height: 6rem;
}

.modal {
  display: block;
  position: fixed;
  left: 30%;
  top: 3%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 45rem;
  max-width: 45rem;
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.modalBody {
  padding: 2rem;
  text-align: justify;
}

.modalBody h3 {
  padding-bottom: 1rem;
  text-align: center;
}

.modalBody p {
  padding-bottom: 1rem;
}

.modalBody p:hover {
  font-weight: bold;
  cursor: pointer;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.modalImg {
  width: 6rem;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.back {
  cursor: pointer;
}

.profile {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: 155px;
  grid-gap: 20px;
  padding: 1.5rem;
  /* background-color: #2196F3; */
}

.profile h4 {
  padding: 1rem;
}

.profile p {
  padding-left: 1rem;
}

.profile ul {
  padding-left: 2rem;
}

.profile ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ffb463ff; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.profile1 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.profile2 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 4;
}

.profile3 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

.profile4 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 5;
}

.profile5 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.pieContainer {
  height: 150px;
  position: relative;
  margin-left: 10rem;
  margin-top: 2rem;
  z-index: 1;
}

.pieBackground {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  z-index: 1;
}

.pie {
  transition: all 1s;
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  clip: rect(0px, 75px, 150px, 0px);
  z-index: 1;
}

.hold {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  clip: rect(0px, 150px, 150px, 75px);
  z-index: 1;
}

#pieSlice1 .pie {
  background-color: #1b458b;
  /* transform:rotate(30deg); */
}

#pieSlice2 {
  transform: rotate(36deg);
}

#pieSlice2 .pie {
  background-color: #0a0;
  transform: rotate(60deg);
}

#pieSlice3 {
  transform: rotate(72deg);
}

#pieSlice3 .pie {
  background-color: #f80;
  transform: rotate(120deg);
}

#pieSlice4 {
  transform: rotate(108deg);
}

#pieSlice4 .pie {
  background-color: #08f;
  transform: rotate(10deg);
}

#pieSlice5 {
  transform: rotate(144deg);
}

#pieSlice5 .pie {
  background-color: #a04;
  transform: rotate(70deg);
}

#pieSlice6 {
  transform: rotate(180deg);
}

#pieSlice6 .pie {
  background-color: #ffd700;
  transform: rotate(70deg);
}

#pieSlice7 {
  transform: rotate(216deg);
}

#pieSlice7 .pie {
  background-color: magenta;
  transform: rotate(70deg);
}

#pieSlice8 {
  transform: rotate(252deg);
}

#pieSlice8 .pie {
  background-color: lightblue;
  transform: rotate(70deg);
}

#pieSlice9 {
  transform: rotate(288deg);
}

#pieSlice9 .pie {
  background-color: gray;
  transform: rotate(70deg);
}

#pieSlice10 {
  transform: rotate(324deg);
}

#pieSlice10 .pie {
  background-color: lightgreen;
  transform: rotate(70deg);
}

.circle1 {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 100%;
  top: -23px;
  left: -25px; 
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: black;
  z-index: 0;
}

.circle2 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: red;
  border-radius: 100%;
  top: 0px;
  left: -25px; 
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: black;
  z-index: 1;
}

.innerCircle {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 100%;
  top: -23px;
  left: -25px; 
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: black;
  z-index: 0;
  /* inset: 0; */
}

.innerCircle .content1 {
  position: absolute;
  display: block;
  top: 0.2rem;
  left: 8rem;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}

.innerCircle .content2 {
  position: absolute;
  display: block;
  width: 120px;
  top: 2.5rem;
  left: 11rem;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.innerCircle .content3 {
  position: absolute;
  display: block;
  width: 120px;
  top: 5.5rem;
  left: 12rem;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.innerCircle .content4 {
  position: absolute;
  display: block;
  width: 120px;
  top: 8rem;
  left: 12rem;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.innerCircle .content5 {
  position: absolute;
  display: block;
  width: 120px;
  top: 10rem;
  left: 10rem;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.innerCircle .content6 {
  position: absolute;
  display: block;
  width: 120px;
  top: 11.5rem;
  left: 8rem;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.innerCircle .content7 {
  position: absolute;
  display: block;
  width: 120px;
  top: 13rem;
  left: 4rem;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.innerCircle .content10 {
  position: absolute;
  display: block;
  top: 0.5rem;
  left: 0;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

/* .wheel1 {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: relative;
  display: inline-grid;
  place-content: center;
  margin-left: 10rem;
  margin-top: 2rem;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  background-color: white;
  border-color: black;
  border-style: solid
}

.wheel1:before {
  content: "";
  height: 80%;
  width: 80%;
  position: absolute;
  margin-left: 0.85rem;
  margin-top: 0.85rem;
  border-radius: 50%;
  inset: 0;
  background: conic-gradient(red 36deg, orange 36deg 72deg, blue 72deg 108deg, green 108deg 144deg, magenta 144deg 180deg, yellow 180deg 216deg, navy 216deg 252deg, lightblue 252deg 288deg, grey 288deg 324deg, lightgreen 324deg 360deg);
} */

.wheel {
  position:relative;
  height: 200px;
  width: 200px;
  margin-left: 12rem;
  margin-top: 1rem;
  border-radius: 50%;
  border: solid;
  border-color: black;
}

.wheel1 {
  position:absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: conic-gradient(red 36deg, orange 36deg 72deg, blue 72deg 108deg, green 108deg 144deg, magenta 144deg 180deg, yellow 180deg 216deg, navy 216deg 252deg, lightblue 252deg 288deg, grey 288deg 324deg, lightgreen 324deg 360deg);
  opacity: 0.2;
}

.wheel2 {
  position: absolute;
  height: 80%;
  width: 80%;
  top:10%;
  left:10%;
  border-radius: 50%;
  /* background:blue; */
  background: conic-gradient(red 36deg, orange 36deg 72deg, blue 72deg 108deg, green 108deg 144deg, magenta 144deg 180deg, yellow 180deg 216deg, navy 216deg 252deg, lightblue 252deg 288deg, grey 288deg 324deg, lightgreen 324deg 360deg);
  opacity: 0.2;
}

.wheel3 {
  position: absolute;
  height: 60%;
  width: 60%;
  top:20%;
  left:20%;
  border-radius: 50%;
  background: conic-gradient(rgba(255,0,0,0.1) 36deg, orange 36deg 72deg, blue 72deg 108deg, green 108deg 144deg, magenta 144deg 180deg, yellow 180deg 216deg, navy 216deg 252deg, lightblue 252deg 288deg, grey 288deg 324deg, lightgreen 324deg 360deg);
  /* opacity: 0.1; */
}

.wheel4 {
  position: absolute;
  height: 40%;
  width: 40%;
  top:30%;
  left:30%;
  border-radius: 50%;
  background: conic-gradient(red 36deg, orange 36deg 72deg, blue 72deg 108deg, green 108deg 144deg, magenta 144deg 180deg, yellow 180deg 216deg, navy 216deg 252deg, lightblue 252deg 288deg, grey 288deg 324deg, lightgreen 324deg 360deg);
}

/* (A) PIE CHART */
#donut {
  /* (A1) CIRCLE */
  width: 300px; height: 300px;
  border-radius: 50%;

  /* (A2) SEGMENTS */
  background: conic-gradient(
    red 0deg 80deg,
    green 80deg 190deg,
    blue 190deg 360deg
  );
}

/* (B) DONUT "CONVERSION" */
/* (B1) SMALLER CIRCLE */
#donut::before {
  content: "";
  width: 180px; height: 180px;
  border-radius: 50%;
  background: #fff;
}

/* (B2) "CONVERT PIE TO DONUT" - CENTER SMALLER CIRCLE */
#donut {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile6 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 2;
  grid-row-start: 4;
  grid-row-end: 5;
}

.containerBar {
  height: 15px;
  max-width: 90%;
  background-color: #e0e0de;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
  text-align: center;
}

.textBar {
  position: absolute;
  font-size: 0.7rem;
}

.progressBar {
  /* height: 100%; */
  /* max-width: 20rem; */
  background-color: #ffb463ff;
  border-radius: 5px;
  text-align: right;
  font-size: 0.7rem;
  color: black;
}

.userIcon {
  float: left;
  height: 30px;
  width: 30px;
  margin-top: 0.7rem;
  margin-left: 0.6rem;
}

/* Button */

.confirmation {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  padding-top: 0.2rem;
  cursor: pointer;
  font-size: 12px;
  color: #888;
  /* text-transform: capitalize; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.confirmation input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

.confirmation img {
width: 100%;
height: auto;
}

/* Show the checkmark when checked */
.confirmation input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.confirmation .checkmark:after {
left: 6px;
top: 2px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

/* On mouse-over, add a grey background color */
.confirmation:hover input ~ .checkmark {
background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.confirmation input:checked ~ .checkmark {
background-color: #009688ff;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Word */

.wordCheck {
  display: block;
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  cursor: pointer;
  font-size: 12px;
  color: #4b5563;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.wordCheck input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

.wordCheck img {
width: 100%;
height: auto;
}

/* Show the checkmark when checked */
.wordCheck input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.wordCheck .checkmark:after {
left: 6px;
top: 2px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

/* On mouse-over, add a grey background color */
.wordCheck:hover input ~ .checkmark {
background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.wordCheck input:checked ~ .checkmark {
background-color: #009688ff;
}

.iti {
  background-color: gray;
  width: 100%;
  height: 100%;
}

/* SAM */

.samScale {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

.samScale h1 {
  text-transform: capitalize;
}

.samScale input {
  display: none;
}

.samItens {
  display: flex;
  color: black
}

.samControl p {
  text-align: center;
}

.samScale h1 {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 7rem;
  color: black;
}

.samScale form {
  margin-bottom: 7rem;
}

.error {
  color: red;
}

.instructions {
  padding-bottom: 2rem;
}