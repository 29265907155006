@import url("https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap");

body {
  overflow: hidden;
}

.link {
  text-decoration: none;
  color: inherit;
}
